<template>
  <div>
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">
                <b-icon icon="check-circle"></b-icon> Congratulation
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="mt-4 mb-4">
      <div class="container">
        <div class="container-lg container-fluid">
          <div class="mb-4 h4 text-success">
            You has been bought following stuff.
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive bg-white shadow">
                <table class="table table-center table-padding mb-0">
                  <thead>
                    <tr>
                      <th class="py-3" style="min-width: 300px">List</th>
                      <th class="text-right py-3" style="min-width: 160px">
                        Quantity
                      </th>
                      <th class="text-center py-3" style="min-width: 160px">
                        Total
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) of list" :key="index">
                      <td>
                        <div class="d-flex align-items-center">
                          <h6 class="mb-0 ml-3">{{ item.title }}</h6>
                        </div>
                      </td>
                      <td class="text-right">
                        <h6 class="mb-0 ml-3">{{ item.qty }}</h6>
                      </td>
                      <td class="text-center font-weight-bold">
                        THB {{ item.total }}.00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="table-responsive bg-white rounded shadow">
                <table class="table table-center table-padding mb-0">
                  <tbody>
                    <tr>
                      <td class="h6">Subtotal</td>
                      <td class="text-center font-weight-bold">
                        THB {{ totalValue }}
                      </td>
                    </tr>
                    <tr>
                      <td class="h6">Taxes</td>
                      <td class="text-center font-weight-bold">
                        THB {{ totalValue * 0.07 }}
                      </td>
                    </tr>
                    <tr class="bg-light">
                      <td class="h6">Total</td>
                      <td class="text-center font-weight-bold">
                        THB {{ totalValue + totalValue * 0.07 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="my-3 border-top"></div>
            </div>
            <div class="col-12">
              <div class="h4 text-primary">Get Started</div>
              <div class="mb-3">
                ธรรมาฟอร์มพ่อค้า หงวน สเตอริโอออกแบบดิสเครดิต พลานุภาพบึมนู้ด
                เท็กซ์คอร์รัปชันสเก็ตช์ ดีเจมือถือจิ๊กซอว์ผ้าห่มว้อย
                สไปเดอร์โปรเจ็กเตอร์ อึ้มไวอะกร้าโกะมั้ง แฟ็กซ์ฟลุตออกแบบรีไทร์
                มาร์คสเตย์อึ้มโนติส สป็อตโซลาร์
                แอดมิสชันซีเรียสธรรมาไฮเวย์โง่เขลา
                เยอบีราออร์แกนิกแจ็กพ็อตใช้งาน แพนด้า
                ง่าวแช่แข็งซูเอี๋ยเยอบีราปัจเจกชน คอลัมน์เลกเชอร์
              </div>
              <div>
                <b-button variant="primary" @click="manage"
                  >Go to manage your organization</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data: () => ({
    userAccountValue: 1000,
    storageValue: 50,
    liveValue: 2,
    counter: 0,
    list: [],
  }),
  computed: {
    totalValue() {
      return (
        parseInt(this.userAccountValue) +
        parseInt(this.storageValue) +
        parseInt(this.liveValue)
      );
    },
  },
  mounted() {
    this.userAccountValue = this.$store.state.buyConfig.user;
    this.storageValue = this.$store.state.buyConfig.storage;
    this.liveValue = this.$store.state.buyConfig.live;

    this.list = [
      {
        id: 1,
        image: "images/shop/product/s1.jpg",
        title: "User Account",
        // price: 255,
        qty: this.userAccountValue + " Student",
        total: this.userAccountValue,
      },
      {
        id: 2,
        image: "images/shop/product/s3.jpg",
        title: "Storage",
        // price: 520,
        qty: this.storageValue + " GB",
        total: this.storageValue,
      },
      {
        id: 3,
        image: "images/shop/product/s6.jpg",
        title: "Live",
        // price: 160,
        qty: this.liveValue + " Channel",
        total: this.liveValue,
      },
    ];
  },
  methods: {
    manage() {
      location.href = "https://admin.nerdof.com?token=";
    },
  },
};
</script>